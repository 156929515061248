import React, { useEffect, useRef, useState } from "react";
import submit from "./../../../assets/submit.svg";
import mic_icon from "./../../../assets/start-mic.svg";
import mic_stop from "./../../../assets/stop-mic.svg";
import waves from "./../../../assets/listening2.gif";
import CancelIcon from "@mui/icons-material/Cancel";
import { SettingsEthernet } from "@mui/icons-material";
import SendIcon from "@mui/icons-material/Send";
import MicIcon from "@mui/icons-material/Mic";
import CancelRecording from "./../../../assets/cancel-recording.svg";
import SendBtn from "./../../../assets/send.svg";
import SendBtnDisabled from "./../../../assets/send-disabled.svg";
import CloseIcon from "@mui/icons-material/Close";

export default function Input({
  onSend,
  startRecording,
  stopRecording,
  cancelRecording,
  recording,
  transcript,
  text2,
  setText2,
  allowedToSend,
  canvasRef
}) {
  // recording = true
  const inputRef = useRef(null);
  const [text, setText] = useState("");
  const [seconds, setSeconds] = useState(0);
  let interval;

  const formatTime = (totalSeconds) => {
    const minutes = String(Math.floor(totalSeconds / 60)).padStart(2, "0");
    const secs = String(totalSeconds % 60).padStart(2, "0");
    return `${minutes}:${secs}`;
  };

  const handleInputChange = (e) => {
    setText(e.target.value);
  };

  const handleSend = (e) => {
    e.preventDefault();
    if(allowedToSend === false) {
      return;
    }

    if(!text || !text?.trim()) return;
    onSend(text);
    setText("");
    // console.log("handlesend in input");
  };

  const handleCancelRecording = () => {
    clearInterval(interval);
    setSeconds(0);
    cancelRecording();
  };

  const handleSendAudio = () => {
    clearInterval(interval);
    setSeconds(0);
    stopRecording();
  };

  const handleStartRecording = () => {
    if(allowedToSend === false) {
      return;
    }
    startRecording();
  };

  const handleCancelInput = () => {
    setText("");
  }

  return (
    <>
        <div className="padhanisa-inputType">
          <form onSubmit={handleSend}>
            <input
              style={{ width: text ? "90%" : "80%" }}
              type="text"
              onChange={handleInputChange}
              value={text}
              placeholder={"Type your message here..."}
            />
            {text && <span className="inputCross" onClick={handleCancelInput}><CloseIcon
            style={{
              color: "#fff",
              zIndex:"200000",
              position: 'relative'
            }}
            className="saregama-close-icon"
          /></span>}
            {!text && (
              <div className="padhanisa-mic_btn">
                <MicIcon
                  onClick={handleStartRecording}
                  className="padhanisa-mic"
                />
              </div>
            )}
            <div className="padhanisa-btn-container1 cursor-pointer">
              {text ? (<img
              onClick={handleSend}
              title="Send Message"
              src={SendBtn}
              alt="Send Message"
            />): (<img
              title="Send Message"
              src={SendBtnDisabled}
              alt="Send Message"
            />)}
            </div>
          </form>
        </div>
    </>
  );
}
