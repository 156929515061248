import React, { useState, useEffect, useRef, useCallback } from "react";
import BotMessage from "./componentsForChat/BotMessage";
import UserMessage from "./componentsForChat/UserMessage";
import Messages from "./componentsForChat/Messages";
import Input from "./componentsForChat/Input";
import CloseIcon from "@mui/icons-material/Close";
import API from "./ChatbotAPI";
import BotIcon from "../../assets/padhanisa-chat-icon.svg";
import Chatbot from "../../assets/padhanisa-chatbot.svg";
import "./chat.css";
import { notifyError } from "./componentsForChat/notify";
import AudioLoading from "./componentsForChat/AudioLoding";
import Mute from "./../../assets/mute-icon.svg";
import SendBtn from "./../../assets/send.svg";
import Unmute from "./../../assets/unmute-icon.svg";
import CancelRecording from "./../../assets/cancel-recording.svg";
import RecorderTimer from "./componentsForChat/RecorderTimer";
import AgentHandOffMessage from "./componentsForChat/AgentHandOffMessage";
import TalkToAnAgentHanfOff from "./componentsForChat/TalkToAnAgentHandOff";

const ChatbotSaReGaMa = (props) => {
  const [messages, setMessages] = useState([]);
  // console.log("msg -- ", messages);
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [recording, setRecording] = useState(false);
  const [audioBlob, setAudioBlob] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setLoading] = useState(null);
  const [text2, setText2] = useState("");
  const [globaMute, setGlobalUnmute] = useState(1);
  const [allowedToSend, setAllowedToSend] = useState(true);
  const [talkToAnAgent, setTalkToAnAgent] = useState(false);
  const [current, setCurrent] = useState({
    cwc: false,
    tta: false,
    aho: false,
  });
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);
  const cancelRecRef = useRef(false);
  const audioRef = useRef([]);
  const analyserRef = useRef(null);
  let audioContext = null;
  let timeoutIds = [];
  const canvasRef = useRef(null);
  const recognitionRef = useRef(null);
  const playonceRef = useRef(true);
  const agentHandOff = useRef([]);
  const msgsRef = useRef([]);
  const currBotInd = useRef(0);
  const countDown = useRef(null);
  const activateRef = useRef(false);

  const stopAllAudio = () => {
    if (audioRef.current && audioRef.current.length) {
      audioRef.current.forEach((item) => {
        if (item) {
          item?.audioPlayer?.pause();
          item?.setPlay(false);
        }
      });
    }
  };

  const startRecording = (e) => {
    stopAllAudio();
    if (e) {
      e.preventDefault();
    }
    audioChunksRef.current = [];
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        const tempaudioContext = new (window.AudioContext ||
          window.webkitAudioContext)();
        audioContext = tempaudioContext;

        const source = tempaudioContext.createMediaStreamSource(stream);
        const gainNode = tempaudioContext.createGain();
        gainNode.gain.value = 1;
        source.connect(gainNode);
        const tmpanalyser = tempaudioContext.createAnalyser();

        if (tmpanalyser) {
          tmpanalyser.fftSize = 2048;
          tmpanalyser.smoothingTimeConstant = 0.8;
        }
        // console.log("analyserRef.current before --", analyserRef.current);
        analyserRef.current = tmpanalyser;
        // console.log("analyserRef.current after --", analyserRef.current);

        gainNode.connect(tmpanalyser);

        let media = new MediaRecorder(stream);
        media.ondataavailable = (e) => {
          audioChunksRef.current.push(e.data);
        };
        media.onstop = async () => {
          if (
            cancelRecRef.current === false &&
            audioChunksRef.current &&
            audioChunksRef.current.length > 0
          ) {
            countDown.current = null;
            const audioBlob = new Blob(audioChunksRef.current, {
              type: "audio/wav",
            });
            setAudioBlob(audioBlob);
            const formData = new FormData();
            formData.append("audio_file", audioBlob, "audio.wav");
            const data = await API.getTextReponse(formData);
            if (data?.response_text !== "\n") {
              displayUserAudioRes(data);
            } else {
              setLoading(false);
            }
          } else {
            audioChunksRef.current = [];
            cancelRecRef.current = false;
          }
        };
        media.start();
        mediaRecorderRef.current = media;
        setRecording(true);
        // console.log("calling draw waveform");
        drawBarWaveform();
        setTimeout(() => {
          checkForSilence();
        }, 10);
        // console.log("out condition");
        setLoading(false);
      })
      .catch((err) => {
        // console.log("error in fetching");
        setErrorMessage("Error accessing microphone: " + err.message);
      });
  };

  // Function to add a timeout and track its identifier
  const setTrackedTimeout = (callback, delay) => {
    const timeoutId = setTimeout(callback, delay);
    timeoutIds.push(timeoutId);
    return timeoutId;
  };

  // Function to clear all tracked timeouts
  const clearAllTimeouts = () => {
    timeoutIds.forEach(clearTimeout); // Clear each timeout
    timeoutIds = []; // Reset the array
  };

  const checkForSilence = () => {
    // console.log("checking for silence");
    if (analyserRef.current) {
      const buffer = new Uint8Array(analyserRef.current.fftSize);
      // console.log("Buffer values:", buffer);
      analyserRef.current.getByteFrequencyData(buffer);
      const total = buffer.reduce((acc, val) => acc + val, 0);
      // console.log("total energy -- ", total);
      const isSilent = total < 20000;

      if (isSilent) {
        // console.log("Silence detected, stopping recording in 3 seconds");
        if (!countDown.current) {
          countDown.current = new Date();
        }
        // console.log("timeDiff -- ", new Date().getTime() - new Date(countDown.current).getTime());
        if (
          new Date().getTime() - new Date(countDown.current).getTime() >=
          2700
        ) {
          if (
            mediaRecorderRef.current &&
            mediaRecorderRef.current.state === "recording"
          ) {
            // console.log("stop recording called due to silence");
            stopRecording();
          }
        } else {
          clearAllTimeouts();
          setTrackedTimeout(checkForSilence, 10);
        }
      } else {
        countDown.current = null;
        clearAllTimeouts();
        setTrackedTimeout(checkForSilence, 10);
        // console.log("Resetting silence timer to check for silence in 100ms");
      }
    } else {
      // console.error("analyserRef.current node is not initialized.");
    }
  };

  const colors = [
    "rgba(255,255,255)", // white
  ];

  const drawBarWaveform = () => {
    if (!canvasRef.current) {
      setTimeout(() => {
        drawBarWaveform();
      }, 0);
    }

    if (!analyserRef.current || !canvasRef.current) {
      return;
    }

    const canvas = canvasRef.current;
    const canvasContext = canvas.getContext("2d");
    const bufferLength = analyserRef.current.frequencyBinCount;
    const dataArray = new Uint8Array(bufferLength);

    const draw = () => {
      analyserRef.current.getByteFrequencyData(dataArray);
      canvasContext.clearRect(0, 0, canvas.width, canvas.height);

      const barWidth = (canvas.width / bufferLength) * 2;
      let barHeight;
      let x = 0;

      const centerY = canvas.height / 2;

      for (let i = 0; i < bufferLength; i++) {
        barHeight = dataArray[i] / 2;

        // Pick a color from the colors array based on bar height
        const colorIndex = Math.floor((barHeight / 128) * colors.length);
        const barColor = colors[colorIndex] || colors[0];

        canvasContext.fillStyle = barColor;

        // Draw bar from the center line up and down
        canvasContext.fillRect(x, centerY - barHeight / 2, barWidth, barHeight);
        x += barWidth + 1;
      }

      requestAnimationFrame(draw);
    };

    draw();
  };

  const stopRecording = useCallback((e) => {
    if (e) {
      e.preventDefault();
    }
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      clearAllTimeouts();
      setRecording(false);
      if (recognitionRef.current) {
        recognitionRef.current.stop();
      }
      setLoading(true);
    } else {
      notifyError("Something went wrong!");
    }
  }, []);

  const handleCloseModal = () => {
    stopAllAudio();
    setIsModalOpen(true);
  };
  const handleOpenModal = () => {
    setIsModalOpen(false);
  };
  useEffect(() => {
    async function loadWelcomeMessage() {
      setAllowedToSend(false);
      localStorage.setItem("volume", 1);
      const tempInd = currBotInd.current;
      currBotInd.current++;

      setMessages([
        <BotMessage
          key="0"
          fetchAudio={async (text) =>
            talkToAnAgent
              ? () => {}
              : await API.getAudioResponse({ response_text: text })
          }
          volume={globaMute}
          playOnce={true}
          audio={audioRef}
          msgsRef={msgsRef}
          botIndex={tempInd}
          agentHandOffArr={agentHandOff.current}
          fetchMessage={async () =>
            await API.GetChatbotResponse("hi", talkToAnAgent)
          }
          handleAgentHandOffMessage={handleAgentHandOffMessage}
          showAudioControls={!talkToAnAgent}
        />,
      ]);
      playonceRef.current = false;
      setAllowedToSend(true);
    }
    playonceRef.current && loadWelcomeMessage();
    return () => {
      if (audioContext) {
        audioContext.close();
      }
    };
  }, []);

  const send = async (text) => {
    // console.log("send in parent");
    const tempInd = currBotInd.current;
    currBotInd.current++;
    stopAllAudio();
    const newMessages = messages.concat(
      <UserMessage key={messages.length + 1} text={text} />,
      <BotMessage
        key={messages.length + 2}
        volume={globaMute}
        audio={audioRef}
        msgsRef={msgsRef}
        botIndex={tempInd}
        agentHandOffArr={agentHandOff.current}
        fetchMessage={async () =>
          await API.GetChatbotResponse(text, talkToAnAgent)
        }
        fetchAudio={async (text) =>
          talkToAnAgent
            ? () => {}
            : await API.getAudioResponse({ response_text: text })
        }
        handleAgentHandOffMessage={handleAgentHandOffMessage}
        showAudioControls={!talkToAnAgent}
      />
    );
    setMessages(newMessages);
  };

  const cancelRecording = () => {
    if (mediaRecorderRef.current) {
      cancelRecRef.current = true;
      audioChunksRef.current = [];
      clearAllTimeouts();
      mediaRecorderRef.current.stop();
      setRecording(false);
    }
  };

  const displayUserAudioRes = (data) => {
    const tempInd = currBotInd.current;
    currBotInd.current++;
    if (data && data?.response_text) {
      // console.log("parent data - ", data);
      let newMessages = [...messages];
      newMessages = newMessages.concat(
        <UserMessage key={messages.length} text={data?.response_text} />,
        <BotMessage
          key={messages.length + 1}
          volume={globaMute}
          msgsRef={msgsRef}
          audio={audioRef}
          botIndex={tempInd}
          agentHandOffArr={agentHandOff.current}
          fetchMessage={async () =>
            await API.GetChatbotResponse(data?.response_text, talkToAnAgent)
          }
          fetchAudio={async (text) =>
            talkToAnAgent
              ? () => {}
              : await API.getAudioResponse(
                  { response_text: text },
                  talkToAnAgent
                )
          }
          handleAgentHandOffMessage={handleAgentHandOffMessage}
          showAudioControls={!talkToAnAgent}
        />
      );
      setLoading(false);
      setMessages(newMessages);
    } else {
      let newMessages = [...messages];
      newMessages = newMessages.concat(
        <UserMessage
          key={messages.length}
          text={"An error occurred while fetching the response."}
        />
      );
      setLoading(false);
      setMessages(newMessages);
    }
  };

  const handleAgentHandOffMessage = () => {
    // console.log("agent hand off parent");
    setCurrent((prev) => ({ ...prev, aho: !current.aho }));
    activateRef.current = true;
  };
  useEffect(() => {
    if (activateRef.current) {
      let newMessages = [...messages];
      // console.log("messages 1 - ", newMessages);
      newMessages = newMessages.concat(
        <AgentHandOffMessage
          key={messages.length}
          handleContinueWithChat={handleContinueWithChat}
          handleContinueWithAgent={handleContinueWithAgent}
        />
      );
      // console.log("messages 2 - ", newMessages);
      setMessages(newMessages);
      // console.log("aho called");
    }
  }, [current?.aho]);

  useEffect(() => {
    if (activateRef.current) {
      // console.log("continue with chat");
      let newMessages = [...messages];
      newMessages.pop();
      // console.log("messages len before - ", newMessages.length);
      newMessages = newMessages.concat(
        <UserMessage key={messages.length} text={"Continue with the chat"} />
      );
      // console.log("messages len after - ", newMessages.length);
      setMessages([...newMessages]);
      // console.log("cwc called");
    }
  }, [current.cwc]);

  useEffect(() => {
    if (activateRef.current) {
      const tempInd = currBotInd.current;
      currBotInd.current++;
      // console.log("continue with agent");
      let newMessages = [...messages];
      newMessages.pop();
      // console.log("messages len before - ", newMessages.length);
      newMessages = newMessages.concat(
        <UserMessage key={messages.length} text={"Talk to an agent"} />,
        <TalkToAnAgentHanfOff
          key={messages.length + 1}
          text={
            "We are transferring this chat, to a support executive who specializes in your concerned query for quicker resolution."
          }
        />,
        <TalkToAnAgentHanfOff
          key={messages.length + 2}
          text={"Support executive Fiza will be assisting you"}
        />,
        <BotMessage
          key={messages.length + 3}
          volume={globaMute}
          msgsRef={msgsRef}
          audio={audioRef}
          botIndex={tempInd}
          agentHandOffArr={agentHandOff.current}
          fetchMessage={async () =>
            await API.GetChatbotResponse("", talkToAnAgent)
          }
          fetchAudio={async (text) =>
            talkToAnAgent
              ? () => {}
              : await API.getAudioResponse(
                  { response_text: text },
                  talkToAnAgent
                )
          }
          handleAgentHandOffMessage={handleAgentHandOffMessage}
          showAudioControls={!talkToAnAgent}
        />
      );
      // console.log("messages len after - ", newMessages.length);
      setMessages([...newMessages]);
      // console.log("tta called");
    }
  }, [current.tta]);

  const handleContinueWithChat = () => {
    stopAllAudio();
    setTalkToAnAgent(false);
    setCurrent((prev) => ({ ...prev, cwc: !current.cwc }));
    activateRef.current = true;
  };

  const handleContinueWithAgent = () => {
    stopAllAudio();
    setTalkToAnAgent(true);
    setCurrent((prev) => ({ ...prev, tta: !current.tta }));
    activateRef.current = true;
  };

  const handleMuteAudio = () => {
    if (audioRef.current && audioRef.current.length) {
      audioRef.current.forEach((item) => {
        if (item) {
          item?.audioPlayer?.mute(true);
        }
      });
    }
  };

  const handleUnMuteAudio = () => {
    if (audioRef.current && audioRef.current.length) {
      audioRef.current.forEach((item) => {
        if (item) {
          item?.audioPlayer?.mute(false);
        }
      });
    }
  };

  const handleMute = () => {
    handleMuteAudio();
    localStorage.setItem("volume", 0);
    setGlobalUnmute(0);
  };

  const handleUnmute = () => {
    handleUnMuteAudio();
    localStorage.setItem("volume", 1);
    setGlobalUnmute(1);
  };

  return (
    <>
      {!isModalOpen && (
        <div className="chatbot-saregama">
          <div className="padhanisa-header">
            <img src={Chatbot} alt="Bot Icon" className="bot-icon" />
            &nbsp;
            <span className="headText-saregama">Padhanisa</span>
            <span className="cross">
              {globaMute ? (
                <img
                  title="Mute Audio"
                  width={19}
                  height={19}
                  src={Unmute}
                  alt="Mute Audio"
                  onClick={handleMute}
                />
              ) : (
                <img
                  title="Unmute Audio"
                  width={19}
                  height={19}
                  src={Mute}
                  alt="Mute Audio"
                  onClick={handleUnmute}
                />
              )}
            </span>
            {/* <span className="cross">
              <CloseIcon onClick={() => handleCloseModal()} />
            </span> */}
          </div>
          <Messages messages={messages} />
          {!isLoading ? (
            recording ? (
              <div className="recording-container">
                <div className="wave-image">
                  <canvas ref={canvasRef} />
                </div>
                <div className="recording-btn-container">
                  <RecorderTimer />
                  <div
                    onClick={cancelRecording}
                    className="center-container cursor-pointer wave-container"
                  >
                    <img
                      title="Cancel Recording"
                      src={CancelRecording}
                      alt="Cancel Recording"
                    />
                  </div>
                  <div className="center-container cursor-pointer">
                    <img
                      onClick={stopRecording}
                      title="Send Audio"
                      src={SendBtn}
                      alt="Send Audio"
                    />
                  </div>
                </div>
              </div>
            ) : (
              <Input
                startRecording={startRecording}
                onSend={send}
                text2={text2}
                setText2={setText2}
                allowedToSend={allowedToSend}
              />
            )
          ) : (
            <AudioLoading />
          )}
        </div>
      )}
      {isModalOpen ? (
        <>
          <div className="bot-icon-saregama">
            <img
              src={BotIcon}
              alt="img"
              title="Click to open chat"
              onClick={handleOpenModal}
            />
          </div>

          <div className="assistance-popup-container">
            <div onClick={handleOpenModal} className="assistance-popup">
              Need Any Assistance ?
            </div>
          </div>
        </>
      ) : (
        <div
          onClick={() => handleCloseModal()}
          className="close-icon-container"
        >
          <CloseIcon
            style={{
              height: "2.7rem",
              width: "2.7rem",
              opacity: "0.8",
              color: "#DE1F28",
            }}
            className="saregama-close-icon"
          />
        </div>
      )}
    </>
  );
};

export default ChatbotSaReGaMa;
