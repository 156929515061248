import React, { useEffect, useRef } from "react";

export default function Messages({ messages }) {
  const messagesRef = useRef(null);

  useEffect(() => {
    if (messagesRef) {
      messagesRef.current.addEventListener("DOMNodeInserted", (event) => {
        const { currentTarget: target } = event;
        target.scroll({ top: target.scrollHeight, behavior: "smooth" });
      });
    }
  }, []);
  return (
    <>
      <div ref={messagesRef} className="messages">
        {messages}
      </div>
    </>
  );
}
