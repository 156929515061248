import audioLoader from "../../../assets/audio_loader1.gif";
import ellipsis from "./../../../assets/ellipse-dots.gif"

export default function AudioLoading() {
  return (
    <div className="input" style={{ borderTop: "2px solid #1F1F1F", backgroundColor: '#1F1F1F' }}>
      <img src={ellipsis} className="audio-loader-img" alt="audio-loader" />
      <div style={{marginBottom: "10px", fontSize: "15px", fontWeight: "500", color: "white"}}>Processing Audio</div>
    </div>
  );
}
