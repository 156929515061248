import React, { useState, useEffect } from "react";
import BotIcon from "../../../assets/padhanisa-bot-icon.svg";
import cookie from "react-cookies";
import { useAudioPlayer } from 'react-use-audio-player';
import Replay from "./../../../assets/replay-icon.svg";
import Play from "./../../../assets/play-icon.svg";
import Pause from "./../../../assets/pause-icon.svg";

export default function BotMessage({
  fetchMessage,
  fetchAudio,
  playOnce,
  audio,
  msgsRef,
  botIndex,
  agentHandOffArr,
  handleAgentHandOffMessage,
  showAudioControls
}) {
  const audioPlayer = useAudioPlayer();
  const [showControls, setShowControls] = useState(showAudioControls)
  const [isLoading, setLoading] = useState(
    botIndex >= 0 && msgsRef.current[botIndex] ? false : true
  );
  const [message, setMessage] = useState(
    botIndex >= 0 && msgsRef.current[botIndex] ? msgsRef.current[botIndex] : ""
  );
  const profile = cookie.load("profile");
  const [play, setPlay] = useState(false);
  const [muted, setMuted] = useState(false);
  const [donePlaying, setDonePlaying] = useState(false);
  const [index, setIndex] = useState(null);
  // console.log("index -- ", index)
  const [agentHandOff, setAgentHandOff] = useState(true)
  const sentiment_responses = [
    "I understand that you are not satisfied with my previous responses. I am here to help and want to ensure that you get the assistance you need. If you would like to discuss your issue further, I can try to provide more information or would you like to talk to an agent?",
    "I apologize for any inconvenience my previous responses may have caused. I am committed to assisting you and ensuring you receive the help you need. If you wish to discuss your issue in more detail, I am here to provide further information or would you like to connect to an agent?",
    "I'm sorry if my previous responses did not meet your expectations. My goal is to help you and make sure you get the necessary support. If you'd like to continue the discussion, I can offer additional information or should I arrange for you to speak with an agent?",
    "I regret any frustration my earlier replies might have caused. I'm dedicated to helping you and ensuring your needs are met. Should you wish to delve deeper into your issue, I am ready to give more details or shall I put you in touch with an agent?",
    "I understand that you would like to speak with a human representative. I am happy to assist you further, but if you want I would connect you with an agent?",
    "I understand that you are not happy with the response. I am happy to assist you further, but if you want I would connect you with an agent?"
    ]
  const getVolume = () => localStorage.getItem("volume");

  const stopAllAudio = () => {
    if (audio.current && audio.current.length) {
      audio.current.forEach((item) => {
        if(item){
          item?.audioPlayer?.pause();
          item?.setPlay(false)
        }
      });
    }
  };

  const checkForAgentHandOff = (botMsg) => {
    return (sentiment_responses.filter(item => item === botMsg)?.length > 0)
  }

  useEffect(() => {
    agentHandOffArr.push(false)
    setAgentHandOff(false)

    async function loadMessage() {
      const msg = await fetchMessage();
      if (msg){
        if(playOnce) {
          audioPlayer.load(
            "https://myy-audio-bucket.s3.eu-north-1.amazonaws.com/answer.mp3",
            {
              autoplay: true,
            }
          );
          audioPlayer.setVolume(getVolume());
          audioPlayer.play();
          audio.current.push({
            audioPlayer,
            setPlay: (params) => setPlay(params),
          });
        }else{
        const res2  = await fetchAudio(msg)
        if(res2) {
          const blob = new Blob([res2], { type: 'audio/mp3' });
          const url = URL.createObjectURL(blob);
          const blobWithExtension = `${url}#t=0.001&.mp3`;
          audioPlayer.load(blobWithExtension, {
            onplay:() => {
              setPlay(true)
            },
            onend: () => {
              setPlay(false)
            }
          })
          // console.log("audioplayer -- - ", audioPlayer)
          audioPlayer.setVolume(getVolume())
          audioPlayer.play()
          audio.current.push({audioPlayer, setPlay: (params) => setPlay(params)})
        }
        if(checkForAgentHandOff(msg)){
          handleAgentHandOffMessage()
        }
      }
        
        setLoading(false);
        setMessage(msg);
        msgsRef.current.push(msg)
      }else{
        console.error("Error fetching message")
      }
    }
    botIndex >= 0 && !msgsRef.current[botIndex] && loadMessage();
  }, []);

  // Function to convert bold markdown and new lines to HTML
  const convertToBoldAndNewlines = (text) => {
    // Convert bold markdown to HTML <b> tags
    const boldPattern = /\*\*(.*?)\*\*/g;
    let htmlText = text.replace(boldPattern, '<b class="bold">$1</b>');
    // Replace multiple newlines with <div> and <br /> for each newline
    const formattedHtml = htmlText.replace(/(\n+)/g, (match) => {
      const brTags = "<br />".repeat(match.length); // Repeat <br /> for each \n
      return `<div></div>${brTags}`;
    });

    // Add <div> tags around text segments split by single newline
    return formattedHtml.replace(/([^\n]+)/g, "<div>$1</div>");
  };

  // React component to display text with bold and new line conversion
  const TextWithBoldAndNewlines = ({ text }) => {
    // Convert the text to HTML with bold and new line tags
    const formattedText = convertToBoldAndNewlines(text);

    return (
      <div>
        {/* Use dangerouslySetInnerHTML to render the formatted HTML */}
        <div dangerouslySetInnerHTML={{ __html: formattedText }}></div>
      </div>
    );
  };

  return (
    <div className="message-container">
      <div className="alignItem">
        <div className="bot-msg-container">
          <img src={BotIcon} alt="Bot Icon" className="bot-icon" />
          <div
            className="bot-message"
            style={{
              padding: isLoading && "3px 14px",
            }}
          >
            {isLoading ? (
              <div className="loader-container">
                <div class="stage">
                  <div class="dot-elastic"></div>
                </div>
              </div>
            ) : message ===
              `Hey ${profile?.givenName} ${profile?.familyName}, How can I help you?` ? (
              message
            ) : (
              <div
                style={{
                  display: "flex",
                  textAlign: "left",
                  flexDirection: agentHandOffArr[botIndex] && "column"
                }}
              >
                <TextWithBoldAndNewlines text={message} />
              </div>
            )}
          </div>
        </div>
        {showControls && !playOnce && (
          <div className="audio-container">
            <div>
              <span>
                {play === false ? (
                  <span
                    title="Play Audio"
                    className="play-pauseBtn"
                    onClick={() => {
                      stopAllAudio()
                      setPlay(true);
                      audioPlayer.setVolume(getVolume())
                      audioPlayer.play()
                    }}
                  >
                    <img src={Play} alt="Play Audio" />
                  </span>
                ) : (
                  <span
                    title="Pause Audio"
                    className="play-pauseBtn"
                    onClick={() => {
                      setPlay(false);
                     audioPlayer.pause();
                    }}
                  >
                    <img src={Pause} alt="Pause Audio" />
                  </span>
                )}
              </span>
              <span
                title="Replay Audio"
                className="play-pauseBtn"
                onClick={() => {
                  stopAllAudio()
                  setPlay(true);
                  audioPlayer.stop()
                  audioPlayer.setVolume(getVolume())
                  audioPlayer.play()
                }}
              >
                <img src={Replay} alt="Replay Audio" />
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
