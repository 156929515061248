import Chat2 from "./Chatbot/chatBot2/chat";
import Chat from "./Chatbot/chatBot/chat";
import Chatfox from "./Chatbot/chatBotfox/chat";
import ChatbotSaReGaMa from "./Chatbot/chatbotSaReGaMa/chatbotSaReGaMa";
function App() {
  return (
    <div className="App">
      <div className="chatbots">
      {/* <Chat /> */}
      {/* <Chat2 />
      <Chatfox /> */}
      <ChatbotSaReGaMa />
      </div>
    </div>
  );
}

export default App;
