import cookie from "react-cookies";
import axios from "axios";
const profile = cookie.load("profile");

const API = {
  GetChatbotResponse: async (message) => {
    const axiosConfig = {
      headers: {
        Authorization: `null`, // Replace with your actual token or remove this line
      },
    };

    if (message === "welcome message") {
      try {
        const response = await axios.post(
          "https://qna.innovationm.com/api/upload/audio/",
          {
            id: "",
            response_text:
              profile?.givenName && profile?.familyName
                ? "Provide me one line welcome message like this:-  Hello ,Welcome!, How can i assist you today?"
                : "Provide me one line welcome message like this:-  Hello ,Welcome!, " +
                  profile?.givenName +
                  " " +
                  profile?.familyName +
                  " How can i assist you today?",
          },
          axiosConfig
        );
        return response?.data;
      } catch (error) {
        console.log("Error fetching welcome message", error);
        return "Error fetching welcome message";
      }
    }
    if (
      message === "hi" ||
      message === "Hi" ||
      message === "HI" ||
      message === "HELLO" ||
      message === "hello" ||
      message === "Hello" ||
      message === "GOOD MORNING" ||
      message === "good morning" ||
      message === "Good Morning" ||
      message === "GOOD EVENING" ||
      message === "Good Evening" ||
      message === "good evening" ||
      message === "Good Afternoon" ||
      message === "GOOD AFTERNOON" ||
      message === "good afternoon"
    ) {
      // return `Hey ${profile?.givenName} ${profile?.familyName}, How can I help you?`;
      return "Hello, Welcome! How can I assist you today?";
    }

    try {
      const response = await axios.post(
        "https://qna.innovationm.com/api/query/",
        {
          query: message,
        },
        axiosConfig
      );

      const responseData = response.data;
      const botMessage = responseData.response;

      // You can customize the response based on specific conditions
      // if (message === "hi") {
      //   return `Hey ${profile?.givenName} ${profile?.familyName}, How can I help you?`;
      // } else {
      return botMessage;
      // }
    } catch (error) {
      console.error("Error:", error.message);
      return "An error occurred while fetching the response.";
    }
  },
  getAudioResponse: async (data) => {
    try {
      const axiosConfig = {
        headers: {
          Authorization: null,
        },
      };
      const res1 = await axios.post(
        "https://qna.innovationm.com/api/upload/audio/",
        data,
        axiosConfig
      );
      return res1.data;
    } catch (error) {
      console.error("Error:", error.message);
      return "An error occurred while fetching the response.";
    }
  },

  getTextReponse: async (data) => {
    try {
      const axiosConfig = {
        headers: {
          Authorization: null,
        },
      };
      const res1 = await axios.post(
        "https://qna.innovationm.com/api/upload/text/",
        data,
        axiosConfig
      );
      return res1.data;
    } catch (error) {
      console.error("Error:", error.message);
      return "An error occurred while fetching the response.";
    }
  },
};

export const getWelcomeMsg = async () => {
  const axiosConfig = {
    headers: {
      Authorization: `null`, // Replace with your actual token or remove this line
    },
  };
  try {
    const response = await axios.post(
      "https://qna.innovationm.com/api/upload/audio/",
      {
        id: "",
        response_text:
          !profile?.givenName && !profile?.familyName
            ? "Provide me one line welcome message like this:-  Hello ,Welcome!, How can i assist you today?"
            : "Provide me one line welcome message like this:-  Hello ,Welcome!, " +
              profile?.givenName +
              " " +
              profile?.familyName +
              " How can i assist you today?",
      },
      axiosConfig
    );
    return response?.data;
  } catch (error) {
    console.log("Error fetching welcome message", error);
    return "Error fetching welcome message";
  }
};

export default API;
