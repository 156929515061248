import BotIcon from "../../../assets/padhanisa-bot-icon.svg";

const AgentHandOffMessage = ({
  handleContinueWithChat,
  handleContinueWithAgent,
}) => {
  return (
    <div className="message-container">
      <div className="alignItem">
        <div className="bot-msg-container">
          <img src={BotIcon} alt="Bot Icon" className="bot-icon" />
          <div className="bot-message">
            <div className="agentHandOff-btn-container">
              <button className="btn-tta" onClick={handleContinueWithAgent}>
              <span className="text">Talk to an agent</span>
              </button>
              <button className="btn-cwc" onClick={handleContinueWithChat}>
                <span className="text">Continue with the chat</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AgentHandOffMessage;
